
.characters {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: auto;

  & > .character {
    margin: 8px;
    flex-grow: 1;
    max-width: 300px;
    opacity: 0.5;
    transition: opacity;
    transition-duration: 0.2s;

    &.selected {
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

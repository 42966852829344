.root {
  display: flex;
  width: 100%;
  height: 100%;
}

.vendorHeadStone {
  height: 60px;
}



.table {
  min-width: 1350px;
}

.cantBuy {
  filter: contrast(0.6);

  & .cantBuyReason {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s;
    background: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5em;

    &:hover {
      opacity: 1;
    }
  }
}
